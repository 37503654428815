const navBreakpoint = 640;
const navBar = $("#navBar");
const navShade = $("#navShade");
const mobileNavButton = $("#mobileNavButton");

//---------------------//
// Nav expand-collapse //
//---------------------//

function getViewportWidth() {
  return window.innerWidth;
}

function showShade() {
  navShade.css({"opacity": "1", "pointer-events": "all"});
}

function hideShade() {
  navShade.css({"opacity": "0", "pointer-events": "none"});
}

function expandSubNav(levelOneLink) {
  levelOneLink.addClass("main-navigation__level-1-a--active");
  levelOneLink.attr("aria-expanded", "true");
  levelOneLink.siblings(".main-navigation__level-2").slideDown("fast");
  showShade();
}

function collapseOtherSubNavs(levelOneLink) {
  const otherLevelOneLinks = levelOneLink.parent().siblings().children(".main-navigation__level-1-a--has-submenu");
  otherLevelOneLinks.each(function () {
    collapseSubNav($(this));
  });
}

function collapseSubNav(levelOneLink) {
  levelOneLink.removeClass("main-navigation__level-1-a--active");
  levelOneLink.attr("aria-expanded", "false");
  levelOneLink.siblings(".main-navigation__level-2").slideUp("fast");
  hideShade();
}

$(".main-navigation__level-1-a--has-submenu").on("click", function (e) {
  if (getViewportWidth() > navBreakpoint) {
    // Prevent default click event
    e.preventDefault();

    const $this = $(this);
    const isExpanded = $this.attr("aria-expanded");

    if (isExpanded == "false") {
      collapseOtherSubNavs($this);
      expandSubNav($this);
    } else {
      collapseSubNav($this);
    }
  }
});

//------------------------//
// Mobile expand-collapse //
//------------------------//

function collapseMobileMenu() {
  navBar.slideUp("fast");
  mobileNavButton.removeAttr("aria-expanded");
  mobileNavButton.children("span").text("Menu");
  hideShade();
}

function expandMobileMenu() {
  navBar.slideDown("fast");
  mobileNavButton.attr("aria-expanded", "true");
  mobileNavButton.children("span").text("Close");
  showShade();
}

mobileNavButton.on("click", function () {

  // Collapse
  if (mobileNavButton.attr("aria-expanded")) {
    collapseMobileMenu();
  }

  // Expand
  else {
    expandMobileMenu();
  }
});

//-----------------------------//
// Resizing across breakpoints //
//-----------------------------//

var prevWindowWidth = 0;

$(document).ready(function () {
  prevWindowWidth = $(window).width();

  if (prevWindowWidth <= navBreakpoint) {
    $(".main-navigation__level-1-a--has-submenu").attr("aria-expanded", "true");
    $(".main-navigation__level-2").css("display", "block");
    navBar.css("display", "none");
  }
});

$(window).resize(function () {
  var currentWindowWidth = $(window).width();

  // Desktop to mobile
  if (prevWindowWidth > navBreakpoint && currentWindowWidth <= navBreakpoint) {
    $(".main-navigation__level-1-a--has-submenu").attr("aria-expanded", "true");
    $(".main-navigation__level-2").css("display", "block");
    navBar.css("display", "none");
  }

  // Mobile to desktop
  else if (prevWindowWidth <= navBreakpoint && currentWindowWidth > navBreakpoint) {
    $(".main-navigation__level-1-a--has-submenu").attr("aria-expanded", "false");
    $(".main-navigation__level-2").css("display", "none");
    navBar.css("display", "block");
  }

  prevWindowWidth = currentWindowWidth;
});

//-------------------//
// Shade close menus //
//-------------------//

navShade.on("click", function() {
  if($(window).width <= navBreakpoint) {
    collapseMobileMenu();
  } else {
    collapseSubNav($(".main-navigation__level-1-a--active"));
  }
});

//--------------------//
// Keyboard traversal //
//--------------------//

// TODO

$(".main-navigation__level-1-a").on("keydown", function (e) {
  const $this = $(this);
  const parentLi = $this.parent();
  const firstLink = $(".main-navigation__level-1-li:first-child").children(".main-navigation__level-1-a");
  const lastLink = $(".main-navigation__level-1-li:last-child").children(".main-navigation__level-1-a");
  const prevLink = parentLi.prev().children(".main-navigation__level-1-a");
  const nextLink = parentLi.next().children(".main-navigation__level-1-a");
  const isFirstItem = parentLi.is(":first-child");
  const isLastItem = parentLi.is(":last-child");

  // Right
  if (e.key == "ArrowRight") {
    if (!isLastItem) {
      nextLink.focus();
    } else {
      firstLink.focus();
    }
  }

  // Left
  if (e.key == "ArrowLeft") {
    if (!isFirstItem) {
      prevLink.focus();
    } else {
      lastLink.focus();
    }
  }

  // Space, enter - just go to the parent item
  if (e.which == 32 || e.key == "Enter") {
    e.preventDefault();
    window.location = $this.attr("href");
  }
});
