// Flip from cover shot to video element
$(".video-embed__cover").on("click", function () {
  var $this = $(this);
  var parent = $this.parents(".video-embed--has-cover");
  var videoEl = $this.siblings(".video-embed__video");

  parent.addClass("video-embed--actioned");

  if (videoEl.is("video")) {
    videoEl.trigger("play");
  } else if (parent.hasClass("video-embed--vimeo")) {
    var iframeId = videoEl.attr("id");
    var iframe = document.getElementById(iframeId);
    var player = new Vimeo.Player(iframe);
    player.play();
  }
});

// Get video asset duration
function getVideoDuration(el) {
  var duration = Math.round(el.duration);
  duration = formatTime(duration);

  $(el).siblings(".video-embed__cover--get-duration").find(".video-embed__video-duration").text(duration);
}

function formatTime(time) {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}
