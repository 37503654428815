var faqCount = 1;

$(".faq").each(function () {
  var $this = $(this);

  $this.find(".faq__header button").attr({
    id: "faq" + faqCount,
    "aria-controls": "faqBody" + faqCount
  });

  $this.find(".faq__body").attr({
    id: "faqBody" + faqCount,
    "aria-labelledby": "faq" + faqCount
  });

  return faqCount++;
});

$(".faq__header button").on("click", function () {
  var $this = $(this);
  var parent = $this.parents(".faq");
  var toggleText = $this.find(".faq__indicator-text");

  if (parent.hasClass("faq--closed")) {
    $this.attr("aria-expanded", "true");
    toggleText.text("Hide answer");
  } else {
    $this.attr("aria-expanded", "false");
    toggleText.text("Show answer");
  }

  parent.toggleClass("faq--closed");
});
