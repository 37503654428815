function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

$(document).ready(function () {
  // Get the banner element
  const bannerEl = $("#wj-cc");

  // Get current consent cookie value (will return empty string if not set)
  const consentStatus = getCookie("wj-cc__consent-status");

  // If no value has been set
  if (!consentStatus) {
    // Show the banner
    bannerEl.removeClass("wj-cc--hidden");

    // Scroll to top of the page
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 50);
  }

  // When user clicks 'accept' button
  $("#wj-cc__accept").on("click", function () {
    // Set the consent status to 'allow' for a year
    setCookie("wj-cc__consent-status", "allow", 365);

    // Hide the banner
    bannerEl.addClass("wj-cc--hidden");
  });

  // Opposite for 'deny' button
  $("#wj-cc__reject").on("click", function () {
    setCookie("wj-cc__consent-status", "deny", 365);
    bannerEl.addClass("wj-cc--hidden");
  });

  // If we're on the cookies policy page
  if($("body").hasClass("body__cookies-policy")) {
    // Select the appropriate radio button
    if(consentStatus == "allow") {
      $("#useAnalytics").prop("checked", true);
    } else {
      $("#dontUseAnalytics").prop("checked", true);
    }

    // Update preferences
    $("#updateCookieSettingsButton").on("click", function() {
      const selectedRadioVal = $("input:radio[name='analytics']:checked").val();
      setCookie("wj-cc__consent-status", selectedRadioVal, 365);
      window.location.href = "/cookie-preferences-saved";
    });
  }
});
