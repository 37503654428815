// Reveal/hide the input clear button as needed
$(".search-form input[type='search']").on("keyup", function () {
  const $this = $(this);
  const inputValue = $this.val();
  const inputClearButton = $this.siblings(".input-clear");

  if(inputValue) {
    inputClearButton.removeClass("input-clear--hide");
  } else {
    inputClearButton.addClass("input-clear--hide");
  }
});

// Clear the input if input clear button is clicked
$(".input-clear").on("click", function() {
  const $this = $(this);
  const input = $this.siblings("input");

  input.val("");
  $this.addClass("input-clear--hide");
});