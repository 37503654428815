var accordionCount = 1;

$(".accordion").each(function () {
  var $this = $(this);

  $this.find(".accordion__header button").attr({
    id: "accordion" + accordionCount,
    "aria-controls": "accordionBody" + accordionCount
  });

  $this.find(".accordion__body").attr({
    id: "accordionBody" + accordionCount,
    "aria-labelledby": "accordion" + accordionCount
  });

  return accordionCount++;
});

$(".accordion__header button").on("click", function () {
  var $this = $(this);
  var parent = $this.parents(".accordion");
  var body = parent.find(".accordion__body");

  if (parent.hasClass("accordion--closed")) {
    $this.attr("aria-expanded", "true");
    body.slideDown("fast");
  } else {
    $this.attr("aria-expanded", "false");
    body.slideUp("fast");
  }

  parent.toggleClass("accordion--closed");
});

// TODO: keyboard navigation